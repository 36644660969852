<template>
    <div style="margin:100px auto;display:block;">

        <motorBusqueda></motorBusqueda>

        <div style="width:90%;margin:50px auto;display:block;">
            <router-link to="/" style="text-decoration:none;color:#00174A;">Regresar</router-link>
        <p style="font-size:30px;color:#00174A;font-weight:700;">
            Resultados de Búsqueda de Inmuebles
        </p>

        <p v-if="busqueda == ''">
            BUSQUEDA SIN RESULTADOS, TAL VEZ PUEDAS SELECCIONAR OTROS INMUEBLES.
        </p>


        <v-layout row justify-center v-if="busqueda != ''">
            <v-flex xs12 md3>
                <div style="width:95%;margin:50px auto;display;block;">
                    <p style="font-size:20px;line-height:30px;font-weight:700;">
                        {{categoria}} EN {{tipo}}, {{municipio}}.
                    </p>
                    <span>{{busqueda.length}} Resultados</span>
                </div>
            </v-flex>
            <v-flex xs12 md9>
                <v-card flat
                style="width:95%;margin:0 auto;display:block;background:transparent;">
                    <v-layout row justify-center>
                        <v-flex xs12 md4
                        v-for="(item, i) in busqueda" :key="i"
                        style="border-radius:10px;"
                        >
                        <v-card-text style="margin:0 auto;display:block;
                        "                        
                        >
                    <v-carousel hide-delimiters height="300" style="border-top-left-radius:10px;
                    border-top-right-radius:10px;"
                    next-icon="fas fa-caret-right"
                    prev-icon="fas fa-caret-left"
                    >
                        <v-carousel-item v-if="item.img1 != ''" :src="item.img1">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img2 != ''" :src="item.img2">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img3 != ''" :src="item.img3">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img4 != ''" :src="item.img4">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img5 != ''" :src="item.img5">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img6 != ''" :src="item.img6">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img7 != ''" :src="item.img7">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img8 != ''" :src="item.img8">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img9 != ''" :src="item.img9">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                        <v-carousel-item v-if="item.img10 != ''" :src="item.img10">
                            <div class="dfdfg" style="bottom:0;position:absolute">
                        <p class="text-center white--text pt-2 px-5">
                            {{item.pr_nombre}}
                        </p>
                    </div>
                        </v-carousel-item>
                    </v-carousel>

                    <v-card style="background:white;border-radius:0;" flat>
                        <v-card-text>


                            <p v-if="item.pr_tipo == 'VENTA'"
                            style="font-size:20px;font-weight:700;padding:10px;color:black;"
                            >
                                Ref: ${{item.pr_precio}} <br>
                                <span style="color:gray;font-size:12px;">{{item.pr_categoria}} en {{item.pr_tipo}}</span>
                            </p>
                            <p v-if="item.pr_tipo == 'ALQUILER' || item.pr_tipo == 'VENTA / ALQUILER'"
                            style="font-size:20px;font-weight:700;padding:10px;color:black;">
                                Ref: ${{item.pr_precio}} | <span>| Canon: ${{item.pr_canon}}</span> <br>
                                <span style="color:gray;font-size:12px;">{{item.pr_categoria}} en {{item.pr_tipo}}</span>
                            </p>


                            
                <v-btn text @click="irInmueble({asesor: item.pr_asesor_uid, id:item.pr_uid})"
                style="
                    border-radius:20px;color:grey;text-transform:none;
                    font-weight:700;text-decoration:none;font-size:14px;
                    ">Ver Detalles</v-btn>
                
                        </v-card-text>
                    </v-card>
                
                    </v-card-text>
                    </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
        </div>


        </div>
</template>

<style>
.dfdfg{
    width:100%;
background: rgba(0,88,176,1);
background: -moz-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,88,176,1)), color-stop(100%, rgba(1,43,82,1)));
background: -webkit-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -o-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: -ms-linear-gradient(top, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
background: linear-gradient(to bottom, rgba(0,88,176,1) 0%, rgba(1,43,82,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0058b0', endColorstr='#012b52', GradientType=0 );
}
.uttomBg {
    text-transform: none;
    background: #00174a;
background: -moz-linear-gradient(top, rgba(0,23,74,1) 0%, rgba(0,23,74,0.8) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,23,74,1)), color-stop(100%, rgba(0,23,74,0.8)));
background: -webkit-linear-gradient(top, rgba(0,23,74,1) 0%, rgba(0,23,74,0.8) 100%);
background: -o-linear-gradient(top, rgba(0,23,74,1) 0%, rgba(0,23,74,0.8) 100%);
background: -ms-linear-gradient(top, rgba(0,23,74,1) 0%, rgba(0,23,74,0.8) 100%);
background: linear-gradient(to bottom, rgba(0,23,74,1) 0%, rgba(0,23,74,0.8) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00174a', endColorstr='#00174a', GradientType=0 );
}
</style>

<script>
import axios from 'axios'
import motorBusqueda from './motorBusqueda.vue'
export default {
    data(){
        return {
            busqueda:[],categoria:'',tipo:'',estado:'',municipio:'',
            precioDelDolar:'',URL_Inmobiliaria:[],sectoresDisponibles:[],
        }
    },
    components:{
        motorBusqueda
    },
    methods:{
        irInmueble(e){
            const _ID = e.id;
            const _ASESOR = e.asesor;
            const _URL = "/Inmueble?inmu="+_ID+"&asesor="+_ASESOR;
            window.open(_URL, "_self")
        },
        loadDolarPrice(){
            fetch('https://s3.amazonaws.com/dolartoday/data.json')
            .then(response => response.json())
  .then(data => this.precioDelDolar=data.USD.dolartoday)
        },
        formats(input)
        {
        var num = input.replace(/\./g,'');
        if(!isNaN(num)){
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1,');
        num = num.split('').reverse().join('').replace(/^[.]/,'');
        this.precioDelDolar = num
        }
        },
        formads(e){
            e.precio1 + e.precio2
        },
        async loadInmuebles(e){
            try {
                await axios.post('https://inmoglobalve.com.ve/php/search.php', {
                    pr_tipo:e.tipo,
                    pr_estado:e.estado,
                    pr_municipio: e.municipio,
                    pr_categoria:e.categoria
                }).then(res=>{
                    this.busqueda = res.data
                })
            } catch (error) {
                console.log(error)
            }
        }
    },
    created(){
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        this.tipo = urlParams.get('tipo');
        this.estado = urlParams.get('estado');
        this.municipio = urlParams.get('municipio');
        this.categoria = urlParams.get('categoria');
        this.loadInmuebles({
            tipo:this.tipo, estado:this.estado, municipio: this.municipio, categoria:this.categoria
        })
    }
}
</script>